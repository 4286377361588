"use strict";
import React from "react";

let Form = {};

let listener = {};

const Init = () => {
    let [list, setList] = React.useState([]);
    listener.set = (get) => setList([...list, get]);
    listener.del = (key) => setList([]);
    return <>{list.map((i) => i)}</>;
};

const open = (e, callback) => {
    listener.set(e);
};

const asyncFunctions = [
    "Input",
    "Password",
    "Search",
    "Button",
    "ButtonIcon",
    "Link",
    "Links",
    "Text",
    "Toogle",
    "Select",
    "Mark",
    "Brands",
    "Price",
    "Categories",
    "Markdown",
    "Time",
    "Files",
    "Date",
];

asyncFunctions.forEach((name) => {
    import("./Form/" + name).then((math) => {
        Form[name] = math.default;
    });
});

Form.Init = Init;
Form.open = open;

export default Form;
